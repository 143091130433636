import { Column, SortOrder } from "ant-design-vue/types/table/column";

const columnProps = [
  "align",
  "ellipsis",
  "colSpan",
  "dataIndex",
  "defaultFilteredValue",
  "defaultSortOrder",
  "filterDropdown",
  "filterDropdownVisible",
  "filtered",
  "filteredValue",
  "filterIcon",
  "filterMultiple",
  "filters",
  "fixed",
  "key",
  "customRender",
  "sorter",
  "sortOrder",
  "sortDirections",
  "title",
  "width",
  "customCell",
  "customHeaderCell",
  "onFilter",
  "onFilterDropdownVisibleChange",
  "slots",
  "scopedSlots",
] as const;

export type SortDirection = SortOrder;
export type ColumnDef = Pick<Column, (typeof columnProps)[number]>;
export type SorterProps = {
  columnKey: string;
  field: string;
  column?: ColumnDef;
  order?: SortDirection;
};
