var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_invoice_controlling_report") } },
        [
          _c(
            "a-form-model",
            {
              ref: "formModel",
              attrs: {
                model: _vm.form,
                "wrapper-col": { span: 12 },
                "label-col": { span: 6 },
                "label-align": "left",
                "data-testid": "inv-controlling-form"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_branch"), prop: "branch" }
                        },
                        [
                          _c("SelectBranch", {
                            attrs: {
                              "label-in-value": "",
                              "data-testid": "inv-controlling-branch"
                            },
                            model: {
                              value: _vm.form.branch,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "branch", $$v)
                              },
                              expression: "form.branch"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
                        [
                          _c("a-range-picker", {
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              placeholder: [
                                _vm.$t("lbl_start_date"),
                                _vm.$t("lbl_end_date")
                              ],
                              "data-testid": "inv-controlling-date"
                            },
                            model: {
                              value: _vm.form.date,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "date", $$v)
                              },
                              expression: "form.date"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer"),
                            prop: "customer"
                          }
                        },
                        [
                          _c("SelectCustomer", {
                            attrs: {
                              "label-in-value": "",
                              "data-testid": "inv-controlling-customer"
                            },
                            model: {
                              value: _vm.form.customer,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customer", $$v)
                              },
                              expression: "form.customer"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_unit_code"),
                            prop: "unitCode"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "allow-clear": "",
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_unit_code")
                              }),
                              "data-testid": "inv-controlling-unit-code"
                            },
                            model: {
                              value: _vm.form.unitCode,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "unitCode", $$v)
                              },
                              expression: "form.unitCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_internal_contract_no"),
                            "label-align": "right",
                            prop: "internalContract"
                          }
                        },
                        [
                          _c("SelectIc", {
                            attrs: {
                              "branch-id": _vm.form.branch
                                ? _vm.form.branch.key
                                : undefined,
                              "customer-id": _vm.form.customer
                                ? _vm.form.customer.key
                                : undefined,
                              "data-testid": "inv-controlling-internal-contract"
                            },
                            model: {
                              value: _vm.form.internalContract,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "internalContract", $$v)
                              },
                              expression: "form.internalContract"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_status"),
                            "label-align": "right",
                            prop: "status"
                          }
                        },
                        [
                          _c("SelectMasterType", {
                            attrs: {
                              name: "STATUS_INTERNAL_CONTRACT",
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_status")
                              }),
                              mode: "multiple",
                              "data-testid": "inv-controlling-status"
                            },
                            model: {
                              value: _vm.form.status,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_by_invoice"),
                            "label-align": "right",
                            prop: "isInvoiced"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              options: _vm.booleanOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_by_invoice")
                              }),
                              "allow-clear": "",
                              "data-testid": "inv-controlling-by-invoice"
                            },
                            model: {
                              value: _vm.form.isInvoiced,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isInvoiced", $$v)
                              },
                              expression: "form.isInvoiced"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_by_backup_unit"),
                            "label-align": "right",
                            prop: "isBackup"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              options: _vm.booleanOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_by_backup_unit")
                              }),
                              "allow-clear": "",
                              "data-testid": "inv-controlling-by-backup-unit"
                            },
                            model: {
                              value: _vm.form.isBackup,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isBackup", $$v)
                              },
                              expression: "form.isBackup"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                "data-testid": "inv-controlling-btn-reset"
                              },
                              on: { click: _vm.handleReset }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                "data-testid": "inv-controlling-find",
                                type: "primary",
                                loading: _vm.loading.find,
                                "html-type": "submit"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.dataReport.data,
              size: "small",
              loading: _vm.loading.find,
              scroll: { x: "calc(100% + 700px)" },
              "row-class-name": function(_, index) {
                return index % 2 ? "bg-white" : "bg-gray-light"
              },
              pagination: {
                showTotal: function() {
                  return _vm.$t("lbl_total_items", {
                    total: _vm.dataReport.totalElements
                  })
                },
                showSizeChanger: true,
                pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                current: _vm.pagination.page,
                total: _vm.dataReport.totalElements
              }
            },
            on: { change: _vm.onChangeTable },
            scopedSlots: _vm._u([
              {
                key: "ellipsis",
                fn: function(text) {
                  return [
                    _c(
                      "a-tooltip",
                      { attrs: { placement: "topLeft", title: text } },
                      [_vm._v(" " + _vm._s(text) + " ")]
                    )
                  ]
                }
              },
              {
                key: "unitCode",
                fn: function(text, row) {
                  return [
                    row.unitHistories
                      ? _c(
                          "span",
                          {
                            staticClass: "text-danger",
                            attrs: {
                              "data-testid": "inv-controlling-unit-histories"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(row.unitHistories.replace(";", ", ")) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        class: { "text-danger": row.endContract },
                        attrs: { "data-testid": "inv-controlling-unit" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              "" +
                                (row.unitHistories ? ", " : "") +
                                row.unitCode
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "mt-2 text-right" },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.download,
                    "data-testid": "inv-controlling-download"
                  },
                  on: { click: _vm.showModals }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("InvoiceControllingDownloadModals", {
        on: { finish: _vm.handleDownload },
        model: {
          value: _vm.modalState.visible,
          callback: function($$v) {
            _vm.$set(_vm.modalState, "visible", $$v)
          },
          expression: "modalState.visible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }