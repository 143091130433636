var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_download"),
        visible: _vm.value,
        "ok-text": _vm.$t("lbl_download"),
        "cancel-text": _vm.$t("lbl_close")
      },
      on: { cancel: _vm.handleClose, ok: _vm.handleOk }
    },
    [
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c("a-icon", { attrs: { type: "info-circle" } }),
          _vm._v(
            " " +
              _vm._s(_vm.$t("lbl_invoice_controlling_report_download_period")) +
              " "
          )
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "formRef",
          attrs: { model: _vm.formState, rules: _vm.validationSchema }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_period"), prop: "dateRange" } },
            [
              _c("a-range-picker", {
                staticClass: "w-100",
                attrs: {
                  format: _vm.DEFAULT_DATE_FORMAT,
                  placeholder: [
                    _vm.$t("lbl_start_date"),
                    _vm.$t("lbl_end_date")
                  ]
                },
                model: {
                  value: _vm.formState.dateRange,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "dateRange", $$v)
                  },
                  expression: "formState.dateRange"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }