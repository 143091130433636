


















import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useInternalContract } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DataListInternalContract } from "@/models/interface/salesOrder.interface";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SelectIc extends Vue {
  @Prop({ required: false, type: Object, default: undefined })
  readonly value!: LabelInValue;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: undefined })
  branchId!: string;

  @Prop({ required: false, type: String, default: undefined })
  customerId!: string;

  options: Option<DataListInternalContract>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  @Watch("customerId")
  onChangeCustomerId(): void {
    this.preFetch();
  }

  @Watch("branchId")
  onChangeBranchId(): void {
    this.preFetch();
  }

  onChange(e?: LabelInValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  fetchOptions(params = new RequestQueryParams()): void {
    const { findAll, toOptions } = useInternalContract();
    this.loading = true;
    findAll(params)
      .then(res => {
        this.options = toOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onSearch(value?: string): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const query: Array<string> = [];
    const defaultQuery: string = this.buildQuery({
      branchId: this.branchId,
      customerId: this.customerId,
    });

    if (value) {
      query.push(builder.push(["documentNo", value], { like: "both" }).build());
    }

    if (defaultQuery) {
      query.unshift(defaultQuery);
    }

    params.search = query.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(
    value?: LabelInValue
  ): Option<DataListInternalContract> | undefined {
    return this.options.find(item => item.value === value?.key);
  }

  buildQuery({
    branchId,
    customerId,
  }: {
    branchId?: string;
    customerId?: string;
  }): string {
    const builder = new SearchBuilder();
    const query: Array<string> = [];

    if (branchId) {
      query.push(builder.push(["branch.secureId", branchId]).build());
    }

    if (customerId) {
      query.push(builder.push(["customer.secureId", customerId]).build());
    }

    return query.join(builder.AND);
  }

  preFetch(): void {
    const params = new RequestQueryParams();
    params.search = this.buildQuery({
      branchId: this.branchId,
      customerId: this.customerId,
    });
    this.fetchOptions(params);
  }
}
