


























































































































































































import { ModalStateBuilder } from "@/builder";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectMasterType from "@/components/custom/select/SelectMasterType.vue";
import {
  InvoiceControllingDownloadModals,
  SelectIc,
} from "@/components/InvoiceControllingReport";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { generateUUID } from "@/helpers/uuid";
import { useBlob, useDate, useInvoiceControllingReport } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  BooleanString,
  FilterField,
  ReportInvControllingResponseDto,
} from "@/models/interface/invoice-controlling-report";
import { SorterProps } from "@/types";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

type Record = ReportInvControllingResponseDto & {
  key: string;
};

@Component({
  components: {
    SelectMasterType,
    SelectBranch,
    SelectCustomer,
    SelectIc,
    InvoiceControllingDownloadModals,
  },
})
export default class InvoiceControllingReport extends Mixins(MNotification) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Ref("formModel")
  formModel!: FormModel;

  dataReport: Pagination<Record> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  pagination = {
    page: ONE,
    limit: DEFAULT_PAGE_SIZE,
    sorts: "billingPeriod:asc" as string | undefined,
  };

  form: FilterField = {
    branch: undefined,
    isInvoiced: undefined,
    date: [],
    internalContract: undefined,
    customer: undefined,
    status: [],
    unitCode: undefined,
    isBackup: undefined,
  };

  columns = [
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      ellipsis: true,
      scopedSlots: { customRender: "ellipsis" },
      sorter: true,
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerAddress",
      key: "customerAddress",
      ellipsis: true,
      scopedSlots: { customRender: "ellipsis" },
      sorter: true,
    },
    {
      title: this.$t("lbl_unit_code"),
      sorter: true,
      key: "unitCode",
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_internal_contract_no"),
      sorter: true,
      dataIndex: "refNumber",
      key: "refNumber",
      customRender: (text: string): string => text || "-",
    },
    {
      title: this.$t("lbl_status_ic"),
      sorter: true,
      dataIndex: "icStatusDesc",
      key: "icStatusDesc",
    },
    {
      title: this.$t("lbl_contract_period"),
      sorter: true,
      dataIndex: "contractPeriod",
      key: "contractPeriod",
      width: "250px",
      customRender: (text: string): string => text || "-",
    },
    {
      title: this.$t("lbl_invoice_number"),
      sorter: true,
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      customRender: (text: string): string => text || "-",
    },
    {
      title: this.$t("lbl_invoice_date"),
      sorter: true,
      key: "invoiceDate",
      dataIndex: "invoiceDate",
      customRender: (text: string): string => dateFormat(text),
    },
    {
      title: this.$t("lbl_amount"),
      sorter: true,
      key: "amount",
      dataIndex: "amount",
      customRender: (text: number): string => currencyFilter(text),
    },
    {
      title: this.$t("lbl_billing_period"),
      sorter: true,
      defaultSortOrder: "ascend",
      dataIndex: "billingPeriod",
      key: "billingPeriod",
      customRender: (text: string): string => text || "-",
    },
  ];

  booleanOptions: Array<Option> = [
    {
      label: this.$t("lbl_yes").toString(),
      key: 0,
      value: "YES" as BooleanString,
    },
    {
      label: this.$t("lbl_no").toString(),
      key: 1,
      value: "NO" as BooleanString,
    },
  ];

  loading = {
    find: false,
    download: false,
  };

  modalState = ModalStateBuilder.build({});

  fetchReport(params?: RequestQueryParams): void {
    const { findAll } = useInvoiceControllingReport();

    this.loading.find = true;
    findAll(params)
      .then(res => {
        this.dataReport = {
          ...res,
          data: res.data.map(item => ({
            ...item,
            key: generateUUID(),
          })),
        };
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  onSubmit(): void {
    this.pagination.page = DEFAULT_PAGE;
    const params: RequestQueryParams = this.buildParamsList(this.form);
    this.fetchReport(params);
  }

  handleReset(): void {
    this.formModel.resetFields();
    this.form.status = [];
  }

  onChangeTable(
    pagination: {
      total: number;
      current: number;
      pageSize: number;
    },
    _,
    { columnKey, order }: SorterProps
  ): void {
    this.pagination.sorts = undefined;
    this.pagination.page = pagination.current;

    if (this.pagination.limit !== pagination.pageSize) {
      this.pagination.page = DEFAULT_PAGE;
    }

    this.pagination.limit = pagination.pageSize;

    if (order === "ascend") {
      this.pagination.sorts = `${columnKey}:asc`;
    } else if (order === "descend") {
      this.pagination.sorts = `${columnKey}:desc`;
    }

    const params = this.buildParamsList(this.form);
    params.page = this.pagination.page - ONE;

    this.fetchReport(params);
  }

  buildSearch(form: FilterField): string {
    const { filterBy } = useInvoiceControllingReport();
    const searchQuery: string = filterBy(form);
    return searchQuery;
  }

  buildParamsList(state: FilterField): RequestQueryParams {
    const params = new RequestQueryParams();
    params.limit = this.pagination.limit;
    params.search = this.buildSearch(state);
    params.sorts = this.pagination.sorts;
    return params;
  }

  buildParamsDownload(
    state: FilterField,
    period: Moment[]
  ): RequestQueryParams {
    const { buildReportHeader } = useInvoiceControllingReport();
    const { toStartDay, toEndDay } = useDate();
    const params: RequestQueryParams = this.buildParamsList(state);
    if (period.length === 2) {
      const [start, end] = period;
      params.dateFrom = toStartDay(start).format();
      params.dateTo = toEndDay(end).format();
    }
    params.params = buildReportHeader(state);
    return params;
  }

  handleDownload({ state }: { state: { dateRange: Moment[] } }): void {
    const { download } = useInvoiceControllingReport();
    const { toDownload } = useBlob();
    const params: RequestQueryParams = this.buildParamsDownload(
      this.form,
      state.dateRange
    );

    this.loading.download = true;
    download(params)
      .then(res => {
        toDownload(res, "invoice_controlling_report.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  showModals(): void {
    this.modalState.open();
  }
}
