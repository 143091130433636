


























import MNotification from "@/mixins/MNotification.vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { FormModel } from "ant-design-vue";
import Vue from "vue";

export default Vue.extend({
  name: "InvoiceControllingDownloadModals",
  mixins: [MNotification],
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      formState: {
        dateRange: [],
      },
      validationSchema: {
        dateRange: {
          required: true,
          message: () => this.$t("lbl_validation_required_error"),
        },
      },
    };
  },
  methods: {
    handleClose(): void {
      this.formState.dateRange = [];
      this.$emit("input", false);
      this.$emit("close");
    },
    handleOk(): void {
      const form = this.$refs.formRef as FormModel;
      form.validate(valid => {
        if (!valid) return;
        this.$emit("finish", { state: this.formState });
      });
    },
  },
});
